import React from 'react';
import './Home.css'; // Import the CSS for styling
import diwaliImage from './diwali.png'; // Import the image
// import ProductList from '../products/ProductList';

const Home = () => {
  return (
    <>
        <div className="unique-image-container">
      <img src={diwaliImage} alt="Diwali" className="unique-diwali-image" />
    </div>
        {/* <ProductList/> */}
    </>

  );
};

export default Home;
