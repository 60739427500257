import { ADD_TO_CART, DECREASE_QUANTITY, INCREASE_QUANTITY, DELETE_ITEM, SET_PRODUCTS } from '../../actions/cartActions/cartActions';




const initialState = {
  cartItems: [], // Initialize as an empty array
  cart: []
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        cartItems: [...state.cartItems, action.payload] // Add new item to cart
      };
    case INCREASE_QUANTITY:
      return {
        ...state,
        cartItems: state.cartItems.map(item =>
          item.cartItemId === action.payload
            ? { ...item, quantity: item.quantity + 1 } // Increase quantity
            : item
        )
      };
    case DECREASE_QUANTITY:
      return {
        ...state,
        cartItems: state.cartItems.map(item =>
          item.cartItemId === action.payload
            ? { ...item, quantity: Math.max(item.quantity - 1, 1) } // Decrease quantity, ensuring it's at least 1
            : item
        )
      };
    case DELETE_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter(item => item.cartItemId !== action.payload) // Remove item from cart
      };
    default:
      return state;
  }
};

export default cartReducer;
