import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-column">
          <h3>About Us</h3>
          <p>
            In compliance with the order of the Hon'ble Supreme Court and High
            Courts, the sale of firecrackers through online platforms is
            strictly prohibited. We are committed to upholding legal standards
            while serving our valued customers. You may proceed by adding
            products to your cart and submitting your enquiry through the
            designated enquiry button. Send the same to our WhatsApp.
            <br />
            <br />
            We fully comply with all applicable legal and statutory
            requirements. Additionally, we utilize only registered and
            authorized transport services in accordance with legal standards,
            similar to other leading companies in Sivakasi.
            <br />
            <br />
            <strong style={{ color: "yellowgreen" }}>
              We will try to reach your doorsteps as nearest as possible,
              however, its responsibility of the customers to collect from the
              nearest registered and legal transport services.
            </strong>
          </p>
        </div>

        <div className="footer-column">
          <h3>Contact Us</h3>
          <h3>Mobile : 9597624265</h3>
          <h3>
            WhatsApp :{" "}
            <a style={{ color: "yellowgreen" }}
              href="https://wa.me/9597624265"
              target="_blank"
              rel="noopener noreferrer"
            >
              9597624265
            </a>
          </h3>
          {/* <h3>WhatsApp : 9597624265</h3> */}
          <h3>
            Email : aurorafireworks.info@gmail.com
            <br />
          </h3>
        </div>

        {/* wa.me/919597624265 */}

        <div className="footer-column">
          <h3>Follow Us</h3>
          <ul className="social-media">
            <li>
              <a
                href="https://www.facebook.com/profile.php?id=61562754008554"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/aurorafireworks91?igsh=MWRjNGg1cWxzZW9pYQ%3D%3D&utm_source=qr"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@Aurorafireworks-d3i"
                target="_blank"
                rel="noopener noreferrer"
              >
                YouTube
              </a>
            </li>
            {/* <li><a href= "#" target="_blank" rel="noopener noreferrer">Twitter</a></li> */}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;