// Action Types
export const INCREASE_QUANTITY = 'INCREASE_QUANTITY';
export const DECREASE_QUANTITY = 'DECREASE_QUANTITY';
export const DELETE_ITEM = 'DELETE_ITEM';
export const ADD_TO_CART = 'ADD_TO_CART';
export const SET_PRODUCTS = 'SET_PRODUCTS'; // Add this line

// Action Creators
// actions/cartActions.js


// Adding quantity to the cart item when adding to cart
export const addToCart = (product) => ({
  type: ADD_TO_CART,
  payload: { ...product, quantity: 1, cartItemId: product._id } // Initialize quantity to 1
});


export const decreaseQuantity = (cartItemId) => ({
  type: DECREASE_QUANTITY,
  payload: cartItemId
});

export const increaseQuantity = (cartItemId) => ({
  type: INCREASE_QUANTITY,
  payload: cartItemId
});

export const deleteItem = (cartItemId) => ({
  type: DELETE_ITEM,
  payload: cartItemId
});

// Add this action creator
export const setProducts = (products) => ({
  type: SET_PRODUCTS,
  payload: products
});
