import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../store/actions/cartActions/cartActions'; // Adjust path as necessary
import productsData from '../../jsonProductsData/jsonproducts.json'; // Adjust path as necessary

const DynamicProductList = ({ category }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProducts = () => {
      try {
        // Simulate loading
        setTimeout(() => {
          // Filter products by category
          const filteredProducts = productsData.filter(product => product.category === category);
          setProducts(filteredProducts);
          setLoading(false);
        }, 1000); // Simulating a delay
      } catch (err) {
        setError('Failed to load products');
        setLoading(false);
      }
    };

    fetchProducts();
  }, [category]); // Dependency on category

  const handleAddToCart = (product) => {
    if (product && product.stock > 0) {
      dispatch(addToCart(product)); // Dispatch addToCart action
      alert(`${product.item_name} added to cart`);
    } else if (product) {
      alert('Currently Unavailable');
    }
  };

  return (
    <div className="products-container">
      <h3>Products List - {category}</h3>

      {loading && <div className="loading">Loading...</div>}
      {error && <div className="error">{error}</div>}

      <ul className="products-list">
        {products.map((product) => (
          <li key={product.id} className="product-item">
            <h4>{product.item_name}</h4>
            {/* Uncomment if imageUrl exists in your data */}
            {product.imageUrl && <img src={product.imageUrl} alt={product.item_name} />}
            <p style={{ textDecoration: 'line-through' }}>Actual Rate: ₹ {product.actual_rate}</p>
            <p>Discount: 50%</p>
            <h4 style={{ color: 'orange', textAlign: 'center' }}>Price: ₹ {product.after_discount}</h4>
            <p>Qty: {product.qty}</p>
            <p>Stock: {product.stock}</p>
            <div>
              <button onClick={() => handleAddToCart(product)}>Add to Cart</button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DynamicProductList;



























// import React, { useEffect, useState } from 'react';
// import productsData from '../../jsonProductsData/jsonproducts.json'; // Adjust path as necessary

// const DynamicProductList = ({ category }) => {
//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchProducts = () => {
//       try {
//         // Simulate loading
//         setTimeout(() => {
//           // Filter products by category
//           const filteredProducts = productsData.filter(product => product.category === category);
//           setProducts(filteredProducts);
//           setLoading(false);
//         }, 1000); // Simulating a delay
//       } catch (err) {
//         setError('Failed to load products');
//         setLoading(false);
//       }
//     };

//     fetchProducts();
//   }, [category]); // Dependency on category

//   const handleAddToCart = (product) => {
//     if (product && product.stock > 0) {
//       // Here, implement your add-to-cart logic
//       alert('Added to Cart');
//     } else if (product) {
//       alert('Currently Unavailable');
//     }
//   };

//   return (
//     <div className="products-container">
//       <h3>Products List - {category}</h3>

//       {loading && <div className="loading">Loading...</div>}
//       {error && <div className="error">{error}</div>}

//       <ul className="products-list">
//         {products.map((product) => (
//           <li key={product.id} className="product-item">
//             <h4>{product.item_name}</h4>
//             {/* Uncomment if imageUrl exists in your data */}
//             {product.imageUrl && <img src={product.imageUrl} alt={product.item_name} />}
//             <p style={{ textDecoration: 'line-through' }}>Actual Rate: ₹ {product.actual_rate}</p>
//             <p>Discount: 50%</p>
//             <h4 style={{ color: 'orange', textAlign: 'center' }}>Price: ₹ {product.after_discount}</h4>
//             <p>Qty: {product.qty}</p>
//             <p>Stock: {product.stock}</p>
//             <div>
//               <button onClick={() => handleAddToCart(product)}>Add to Cart</button>
//             </div>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default DynamicProductList;
