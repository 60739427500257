import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/homePage/Home'

import Navbar from './components/layouts/Navbar';
import Footer from './components/layouts/Footer';
import Header from './components/layouts/Header';

import ProductList from './pages/products/ProductList'
import DynamicProductList from './pages/categorisedProducts/DynamicProductList';




import Cart from './pages/cart/Cart';


import Payment from './pages/payment/Payment';

const App = () => {
  return (
    <div className="wrapper">
      <BrowserRouter>
        <Navbar />
        <div className="content-wrapper">
          <Routes>
             <Route path="/" exact element={<Home/>} />
            <Route path="/chakras" exact element={<DynamicProductList category="GROUND CHAKKAR" />} />
            <Route path="/flowerpots" exact element={<DynamicProductList category="FLOWER POTS" />} />
            <Route path="/garland" exact element={<DynamicProductList category="GARLAND WALA" />} />
            <Route path="/giftbox" exact element={<DynamicProductList category="GIFT BOX (NO DISCOUNT)" />} />
            <Route path="/crackers" exact element={<DynamicProductList category="ELECTRIC CRACKERS" />} />
            <Route path="/fancy" exact element={<DynamicProductList category="FANCY" />} />
            <Route path="/fountains" exact element={<DynamicProductList category="FOUNTAIN" />} />
            <Route path="/sparklers" exact element={<DynamicProductList category="ELECTRIC SPARKLERS" />} />
            <Route path="/rocket" exact element={<DynamicProductList category="ROCKETS" />} />


            <Route path="/cart" element={<Cart />} />



            <Route path="/payment" exact element={<Payment/>} />

          </Routes>
        </div>
        <Header />
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;