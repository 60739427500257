import React from 'react';
import './Header.css';
import leafIcon from '../../assets/eco.jpg'; // Make sure to add this icon to your project

const Header = () => {
  return (
    <header className="eco-header">
      <div className="header-container">
        <div className="logo">
          <img src={leafIcon} alt="Leaf Icon" className="leaf-icon" />
          {/* <h1>Go Green</h1> */}
        </div>
        {/* <nav className="nav-menu">
          <a href="#home">Home</a>
          <a href="#about">About</a>
          <a href="#services">Services</a>
          <a href="#contact">Contact</a>
        </nav> */}
        {/* <button className="cta-button">Go Green</button> */}
      </div>
    </header>
  );
};

export default Header;