import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { increaseQuantity, decreaseQuantity, deleteItem } from '../../store/actions/cartActions/cartActions';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf'; // Import jsPDF
import 'jspdf-autotable'; // Import jsPDF autotable plugin.

import './Cart.css';

const Cart = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cartItems);
  const navigate = useNavigate();

  // State for shipping address
  const [shippingAddress, setShippingAddress] = useState({
    name: '',
    doorNumber: '',
    street: '',
    taluk: '',
    district: '',
    state: '',
    postalCode: '',
    mobileNumber: ''
  });

  const handleIncreaseQuantity = (cartItemId) => {
    dispatch(increaseQuantity(cartItemId));
  };

  const handleDecreaseQuantity = (cartItemId) => {
    dispatch(decreaseQuantity(cartItemId));
  };

  const handleDeleteItem = (cartItemId) => {
    dispatch(deleteItem(cartItemId));
  };

  const handleContinueShopping = () => {
    navigate('/');
  };

  // Function to generate PDF billing with business details
  const generatePDF = () => {
    const doc = new jsPDF();
  
    // Main Header: Aurora Fireworks
    doc.setFontSize(22);
    doc.text('Aurora Fireworks', 105, 20, null, null, 'center'); // Centered
  
    // Subheader: WhatsApp Payment Instruction (smaller than header)
    doc.setFontSize(12);
    const whatsappInstruction = "Send this pdf quotation and paid screenshot to our WhatsApp number: 9597624265";
    doc.text(whatsappInstruction, 105, 30, null, null, 'center'); // Centered below the main header
  
    // Quotation Title
    doc.setFontSize(18);
    doc.text('Quotation', 14, 50);
  
    // Shipping Address
    doc.setFontSize(12);
    doc.text('Shipping Address:', 14, 60);
    doc.text(`Name: ${shippingAddress.name}`, 14, 66);
    doc.text(`Door Number: ${shippingAddress.doorNumber}`, 14, 72);
    doc.text(`Street: ${shippingAddress.street}`, 14, 78);
    doc.text(`Taluk: ${shippingAddress.taluk}`, 14, 84);
    doc.text(`District: ${shippingAddress.district}`, 14, 90);
    doc.text(`State: ${shippingAddress.state}`, 14, 96);
    doc.text(`Postal Code: ${shippingAddress.postalCode}`, 14, 102);
    doc.text(`Mobile Number: ${shippingAddress.mobileNumber}`, 14, 108);
  
    // Table for Cart Items
    const tableColumn = ['Item Name', 'Rs / Item', 'Quantity', 'Total Price'];
    const tableRows = [];
  
    cartItems.forEach(item => {
      const itemData = [
        item.item_name,
        `Rs ${item.after_discount}`,
        item.quantity,
        `Rs ${item.quantity * item.after_discount}`
      ];
      tableRows.push(itemData);
    });
  
    // Generate the table using autoTable
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 120, // Adjust starting position to leave space for header and instructions
    });
  
    // Total Items and Total Amount
    const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);
    const totalAmount = cartItems.reduce((total, item) => total + item.quantity * item.after_discount, 0);
  
    // Display totals below the table
    doc.text(`Total Items: ${totalItems}`, 14, doc.previousAutoTable.finalY + 10);
    doc.text(`Total Amount: Rs ${totalAmount}`, 14, doc.previousAutoTable.finalY + 16);
  
    // Footer Content
    // Footer Content: Positioned slightly above the bottom margin
const footerText = "It's the responsibility of the customer to collect from the nearest registered transport service. We will inform you on time.";
doc.setFontSize(10);  // Reduce font size for the footer to fit within margins
const pageHeight = doc.internal.pageSize.height;  // Get the page height

// Position footer 10 units above the bottom of the page
doc.text(footerText, 105, pageHeight - 10, null, null, 'center');  // Footer content centered near the bottom

    // const footerText = "It's the responsibility of the customer to collect from the nearest registered transport service. We will inform you on time.";
    // doc.text(footerText, 105, 280, null, null, 'center');  // Footer content placed at the bottom, centered
  
    // Save the PDF as 'invoice.pdf'
    doc.save('invoice.pdf');
  };
  
  
  


  // const generatePDF = () => {
  //   const doc = new jsPDF();
    
  //   // Add some spacing before invoice details
  //   doc.setFontSize(18);
  //   doc.text('Quotation', 14, 55);
  //   doc.setFontSize(12);
  //   doc.text('Shipping Address:', 14, 65);
  //   doc.text(`Name: ${shippingAddress.name}`, 14, 71);
  //   doc.text(`Door Number: ${shippingAddress.doorNumber}`, 14, 77);
  //   doc.text(`Street: ${shippingAddress.street}`, 14, 83);
  //   doc.text(`Taluk: ${shippingAddress.taluk}`, 14, 89);
  //   doc.text(`District: ${shippingAddress.district}`, 14, 95);
  //   doc.text(`State: ${shippingAddress.state}`, 14, 101);
  //   doc.text(`Postal Code: ${shippingAddress.postalCode}`, 14, 107);
  //   doc.text(`Mobile Number: ${shippingAddress.mobileNumber}`, 14, 113);

  //   const tableColumn = ['Item Name', 'Rs / Item', 'Quantity', 'Total Price'];
  //   const tableRows = [];

  //   cartItems.forEach(item => {
  //     const itemData = [
  //       item.item_name,
  //       `Rs ${item.after_discount}`,
  //       item.quantity,
  //       `Rs ${item.quantity * item.after_discount}`
  //     ];
  //     tableRows.push(itemData);
  //   });

  //   doc.autoTable({
  //     head: [tableColumn],
  //     body: tableRows,
  //     startY: 120 // Adjust starting position
  //   });

  //   const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);
  //   const totalAmount = cartItems.reduce((total, item) => total + item.quantity * item.after_discount, 0);

  //   doc.text(`Total Items: ${totalItems}`, 14, doc.previousAutoTable.finalY + 10);
  //   doc.text(`Total Amount: Rs ${totalAmount}`, 14, doc.previousAutoTable.finalY + 16);

  //   doc.save('invoice.pdf');
  // };

  const handleCheckout = () => {
    const totalAmount = cartItems.reduce((total, item) => total + item.quantity * item.after_discount, 0);
    
    if (totalAmount < 3000) {
      alert('Minimum order amount is ₹3000 to proceed');
    } else if (Object.values(shippingAddress).some(field => field === '')) {
      alert('Please fill in all shipping details');
    } else {
      alert('Send this pdf quotation and paid screenshot to our WhatsApp number to confirm, உறுதிப்படுத்த இந்த pdf மற்றும் பணம் செலுத்திய ஸ்கிரீன்ஷாட்டை எங்கள் வாட்ஸ்அப் எண்ணுக்கு அனுப்பவும்');
      generatePDF(); // Generate PDF on checkout
      navigate('/payment');
    }
  };
  

  // const handleCheckout = () => {
  //   if (Object.values(shippingAddress).some(field => field === '')) {
  //     alert('Please fill in all shipping details');
  //   } else {
  //     alert('Consolidated PDF genetared for you')
  //     generatePDF(); // Generate PDF on checkout
  //     navigate('/payment');
  //   }
  // };



  // Handle changes in shipping address form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setShippingAddress(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <div className="cart-container">
      <h3>Shopping Cart</h3>
      {cartItems.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        <div className="cart-table-container">
          <table className="cart-table">
            <thead>
              <tr>
                <th>Items</th>
                <th>₹/Item</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {cartItems.map((item) => (
                <tr key={item.cartItemId}>
                  <td>{item.item_name}</td>
                  <td>₹{item.after_discount}</td>
                  <td>
                    <button className="quantity-btn" onClick={() => handleDecreaseQuantity(item.cartItemId)}>-</button>
                    <span>{item.quantity}</span>
                    <button className="quantity-btn" onClick={() => handleIncreaseQuantity(item.cartItemId)}>+</button>
                  </td>
                  <td>₹{item.quantity * item.after_discount}</td>
                  <td>
                    <button className="delete-btn" onClick={() => handleDeleteItem(item.cartItemId)}>X</button>
                  </td>
                </tr>     
              ))}
            </tbody>
          </table>
          
          <div className="cart-total">
            <p><strong>Total Items:</strong> {cartItems.reduce((total, item) => total + item.quantity, 0)}</p>
            <p><strong>Total Amount:</strong> ₹{cartItems.reduce((total, item) => total + item.quantity * item.after_discount, 0)}</p>
          </div>
        </div>
      )}

      {/* Shipping Address Form */}
      <div className="shipping-address">
        <h4>Shipping Address</h4>
        <form>
          <label>
            Name:
            <input type="text" name="name" value={shippingAddress.name} onChange={handleChange} />
          </label>
          <label>
            Door Number:
            <input type="text" name="doorNumber" value={shippingAddress.doorNumber} onChange={handleChange} />
          </label>
          <label>
            Street:
            <input type="text" name="street" value={shippingAddress.street} onChange={handleChange} />
          </label>
          <label>
            Taluk:
            <input type="text" name="taluk" value={shippingAddress.taluk} onChange={handleChange} />
          </label>
          <label>
            District:
            <input type="text" name="district" value={shippingAddress.district} onChange={handleChange} />
          </label>
          <label>
            State:
            <input type="text" name="state" value={shippingAddress.state} onChange={handleChange} />
          </label>
          <label>
            Postal Code:
            <input type="number" name="postalCode" value={shippingAddress.postalCode} onChange={handleChange} />
          </label>
          <label>
            Mobile Number:
            <input type="number" name="mobileNumber" value={shippingAddress.mobileNumber} onChange={handleChange} />
          </label>
        </form>
        <div className="cart-buttons">
          <button className="button-checkout" onClick={handleCheckout}>Confirm Order</button>
          <button className="button-continue-shopping" onClick={handleContinueShopping}>Shop More</button>
        </div>
      </div>
    </div>
  );
};

export default Cart;
















// import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { increaseQuantity, decreaseQuantity, deleteItem } from '../../store/actions/cartActions/cartActions';
// import { useNavigate } from 'react-router-dom';
// import jsPDF from 'jspdf'; // Import jsPDF
// import 'jspdf-autotable'; // Import jsPDF autotable plugin

// import './Cart.css';

// const Cart = () => {
//   const dispatch = useDispatch();
//   const cartItems = useSelector((state) => state.cart.cartItems);
//   const navigate = useNavigate();

//   // State for shipping address
//   const [shippingAddress, setShippingAddress] = useState({
//     name: '',
//     doorNumber: '',
//     street: '',
//     taluk: '',
//     district: '',
//     state: '',
//     postalCode: '',
//     mobileNumber: ''
//   });

//   const handleIncreaseQuantity = (cartItemId) => {
//     dispatch(increaseQuantity(cartItemId));
//   };

//   const handleDecreaseQuantity = (cartItemId) => {
//     dispatch(decreaseQuantity(cartItemId));
//   };

//   const handleDeleteItem = (cartItemId) => {
//     dispatch(deleteItem(cartItemId));
//   };

//   const handleContinueShopping = () => {
//     navigate('/');
//   };

//   // Function to generate PDF billing
//   const generatePDF = () => {
//     const doc = new jsPDF();
//     doc.setFontSize(18);
//     doc.text('Order Invoice', 14, 22);
//     doc.setFontSize(12);
//     doc.text('Shipping Address:', 14, 30);
//     doc.text(`Name: ${shippingAddress.name}`, 14, 36);
//     doc.text(`Door Number: ${shippingAddress.doorNumber}`, 14, 42);
//     doc.text(`Street: ${shippingAddress.street}`, 14, 48);
//     doc.text(`Taluk: ${shippingAddress.taluk}`, 14, 54);
//     doc.text(`District: ${shippingAddress.district}`, 14, 60);
//     doc.text(`State: ${shippingAddress.state}`, 14, 66);
//     doc.text(`Postal Code: ${shippingAddress.postalCode}`, 14, 72);
//     doc.text(`Mobile Number: ${shippingAddress.mobileNumber}`, 14, 78);

//     const tableColumn = ['Item Name', 'Rs / Item', 'Quantity', 'Total Price'];
//     const tableRows = [];

//     cartItems.forEach(item => {
//       const itemData = [
//         item.item_name,
//         `Rs ${item.after_discount}`,
//         item.quantity,
//         `Rs ${item.quantity * item.after_discount}`
//       ];
//       tableRows.push(itemData);
//     });

//     doc.autoTable({
//       head: [tableColumn],
//       body: tableRows,
//       startY: 85
//     });

//     const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);
//     const totalAmount = cartItems.reduce((total, item) => total + item.quantity * item.after_discount, 0);

//     doc.text(`Total Items: ${totalItems}`, 14, doc.previousAutoTable.finalY + 10);
//     doc.text(`Total Amount: Rs ${totalAmount}`, 14, doc.previousAutoTable.finalY + 16);

//     doc.save('invoice.pdf');
//   };

//   const handleCheckout = () => {
//     if (Object.values(shippingAddress).some(field => field === '')) {
//       alert('Please fill in all shipping details');
//     } else {
//       generatePDF(); // Generate PDF on checkout
//       navigate('/login');
//     }
//   };

//   // Handle changes in shipping address form
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setShippingAddress(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };

//   return (
//     <div className="cart-container">
//       <h3>Shopping Cart</h3>
//       {cartItems.length === 0 ? (
//         <p>Your cart is empty</p>
//       ) : (
//         <div className="cart-table-container">
//           <table className="cart-table">
//             <thead>
//               <tr>
//                 <th>Items</th>
//                 <th>₹/Item</th>
//                 <th>Qty</th>
//                 <th>Price</th>
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {cartItems.map((item) => (
//                 <tr key={item.cartItemId}>
//                   <td>{item.item_name}</td>
//                   <td>₹{item.after_discount}</td>
//                   <td>
//                     <button className="quantity-btn" onClick={() => handleDecreaseQuantity(item.cartItemId)}>-</button>
//                     <span>{item.quantity}</span>
//                     <button className="quantity-btn" onClick={() => handleIncreaseQuantity(item.cartItemId)}>+</button>
//                   </td>
//                   <td>₹{item.quantity * item.after_discount}</td>
//                   <td>
//                     <button className="delete-btn" onClick={() => handleDeleteItem(item.cartItemId)}>X</button>
//                   </td>
//                 </tr>     
//               ))}
//             </tbody>
//           </table>
          
//           <div className="cart-total">
//             <p><strong>Total Items:</strong> {cartItems.reduce((total, item) => total + item.quantity, 0)}</p>
//             <p><strong>Total Amount:</strong> ₹{cartItems.reduce((total, item) => total + item.quantity * item.after_discount, 0)}</p>
//           </div>
//         </div>
//       )}

//       {/* Shipping Address Form */}
//       <div className="shipping-address">
//         <h4>Shipping Address</h4>
//         <form>
//           <label>
//             Name:
//             <input type="text" name="name" value={shippingAddress.name} onChange={handleChange} />
//           </label>
//           <label>
//             Door Number:
//             <input type="text" name="doorNumber" value={shippingAddress.doorNumber} onChange={handleChange} />
//           </label>
//           <label>
//             Street:
//             <input type="text" name="street" value={shippingAddress.street} onChange={handleChange} />
//           </label>
//           <label>
//             Taluk:
//             <input type="text" name="taluk" value={shippingAddress.taluk} onChange={handleChange} />
//           </label>
//           <label>
//             District:
//             <input type="text" name="district" value={shippingAddress.district} onChange={handleChange} />
//           </label>
//           <label>
//             State:
//             <input type="text" name="state" value={shippingAddress.state} onChange={handleChange} />
//           </label>
//           <label>
//             Postal Code:
//             <input type="text" name="postalCode" value={shippingAddress.postalCode} onChange={handleChange} />
//           </label>
//           <label>
//             Mobile Number:
//             <input type="text" name="mobileNumber" value={shippingAddress.mobileNumber} onChange={handleChange} />
//           </label>
//         </form>
//         <div className="cart-buttons">
//           <button className="button-continue-shopping" onClick={handleContinueShopping}>Shop More</button>
//           <button className="button-checkout" onClick={handleCheckout}>Confirm Order</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Cart;




































// import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { increaseQuantity, decreaseQuantity, deleteItem } from '../../store/actions/cartActions/cartActions';
// import { useNavigate } from 'react-router-dom';

// import './Cart.css';

// const Cart = () => {
//   const dispatch = useDispatch();
//   const cartItems = useSelector((state) => state.cart.cartItems);
//   const navigate = useNavigate();

//   // State for shipping address
//   const [shippingAddress, setShippingAddress] = useState({
//     name: '',
//     doorNumber: '',
//     street: '',
//     taluk: '',
//     district: '',
//     state: '',
//     postalCode: '',
//     mobileNumber: ''
//   });

//   const handleIncreaseQuantity = (cartItemId) => {
//     dispatch(increaseQuantity(cartItemId));
//   };

//   const handleDecreaseQuantity = (cartItemId) => {
//     dispatch(decreaseQuantity(cartItemId));
//   };

//   const handleDeleteItem = (cartItemId) => {
//     dispatch(deleteItem(cartItemId));
//   };

//   const handleContinueShopping = () => {
//     navigate('/');
//   };

//   const handleCheckout = () => {
//     if (Object.values(shippingAddress).some(field => field === '')) {
//       alert('Please fill in all shipping details');
//     } else {
//       // Process checkout
//       console.log('Shipping Address:', shippingAddress);
//       navigate('/login');
//     }
//   };

//   // Handle changes in shipping address form
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setShippingAddress(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };

//   return (
//     <div className="cart-container">
//       <h3>Shopping Cart</h3>
//       {cartItems.length === 0 ? (
//         <p>Your cart is empty</p>
//       ) : (
//         <div className="cart-table-container">
//           <table className="cart-table">
//             <thead>
//               <tr>
//                 <th>Items</th>
//                 {/* <th>Actual Price</th> */}
//                 <th>₹/Item</th>
//                 <th>Qty</th>
//                 <th>Price</th>
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {cartItems.map((item) => (
//                 <tr key={item.cartItemId}>
//                   <td>{item.item_name}</td>
//                   {/* <td>₹{item.actual_rate}</td> */}
//                   <td>₹{item.after_discount}</td>
//                   <td>
//                     <button className="quantity-btn" onClick={() => handleDecreaseQuantity(item.cartItemId)}>-</button>
//                     <span>{item.quantity}</span>
//                     <button className="quantity-btn" onClick={() => handleIncreaseQuantity(item.cartItemId)}>+</button>
//                   </td>
//                   <td>₹{item.quantity * item.after_discount}</td>
//                   <td>
//                     <button className="delete-btn" onClick={() => handleDeleteItem(item.cartItemId)}>X</button>
//                   </td>
//                 </tr>     
//               ))}
//             </tbody>
//           </table>
          
//           <div className="cart-total">
//             <p><strong>Total Items:</strong> {cartItems.reduce((total, item) => total + item.quantity, 0)}</p>
//             <p><strong>Total Amount:</strong> ₹{cartItems.reduce((total, item) => total + item.quantity * item.after_discount, 0)}</p>
//           </div>

//           {/* <div className="cart-buttons">
//             <button className="button-continue-shopping" onClick={handleContinueShopping}>Shop More</button>
//             <button className="button-checkout" onClick={handleCheckout}>Confirm Order</button>
//           </div> */}
//         </div>
//       )}

//       {/* Shipping Address Form */}
//       <div className="shipping-address">
//         <h4>Shipping Address</h4>
//         <form>
//           <label>
//             Name:
//             <input type="text" name="name" value={shippingAddress.name} onChange={handleChange} />
//           </label>
//           <label>
//             Door Number:
//             <input type="text" name="doorNumber" value={shippingAddress.doorNumber} onChange={handleChange} />
//           </label>
//           <label>
//             Street:
//             <input type="text" name="street" value={shippingAddress.street} onChange={handleChange} />
//           </label>
//           <label>
//             Taluk:
//             <input type="text" name="taluk" value={shippingAddress.taluk} onChange={handleChange} />
//           </label>
//           <label>
//             District:
//             <input type="text" name="district" value={shippingAddress.district} onChange={handleChange} />
//           </label>
//           <label>
//             State:
//             <input type="text" name="state" value={shippingAddress.state} onChange={handleChange} />
//           </label>
//           <label>
//             Postal Code:
//             <input type="text" name="postalCode" value={shippingAddress.postalCode} onChange={handleChange} />
//           </label>
//           <label>
//             Mobile Number:
//             <input type="text" name="mobileNumber" value={shippingAddress.mobileNumber} onChange={handleChange} />
//           </label>
//         </form>
//           <div className="cart-buttons">
//             <button className="button-continue-shopping" onClick={handleContinueShopping}>Shop More</button>
//             <button className="button-checkout" onClick={handleCheckout}>Confirm Order</button>
//           </div>
//       </div>
//     </div>
//   );
// };

// export default Cart;






// import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { increaseQuantity, decreaseQuantity, deleteItem } from '../../store/actions/cartActions/cartActions';
// import { useNavigate } from 'react-router-dom';

// import './Cart.css';

// const Cart = () => {
//   const dispatch = useDispatch();
//   const cartItems = useSelector((state) => state.cart.cartItems);
//   const navigate = useNavigate();

//   const handleIncreaseQuantity = (cartItemId) => {
//     dispatch(increaseQuantity(cartItemId));
//   };

//   const handleDecreaseQuantity = (cartItemId) => {
//     dispatch(decreaseQuantity(cartItemId));
//   };

//   const handleDeleteItem = (cartItemId) => {
//     dispatch(deleteItem(cartItemId));
//   };

//   const handleContinueShopping = () => {
//     navigate('/');
//   };

//   const handleCheckout = () => {
//     navigate('/login');
//   };

//   return (
//     <div className="cart-container">
//       <h3>Shopping Cart</h3>
//       {cartItems.length === 0 ? (
//         <p>Your cart is empty</p>
//       ) : (
//         <div className="cart-table-container">
//           <table className="cart-table">
//             <thead>
//               <tr>
//                 <th>Items</th>
//                 {/* <th>Actual Price</th> */}
//                 <th>Price</th>
//                 <th>Qty</th>
//                 <th>Price</th>
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {cartItems.map((item) => (
//                 <tr key={item.cartItemId}>
//                   <td>{item.item_name}</td>
//                   {/* <td>₹{item.actual_rate}</td> */}
//                   <td>₹{item.after_discount}</td>
//                   <td>
//                     <button className="quantity-btn" onClick={() => handleDecreaseQuantity(item.cartItemId)}>-</button>
//                     <span>{item.quantity}</span>
//                     <button className="quantity-btn" onClick={() => handleIncreaseQuantity(item.cartItemId)}>+</button>
//                   </td>
//                   <td>₹{item.quantity * item.after_discount}</td>
//                   <td>
//                     <button className="delete-btn" onClick={() => handleDeleteItem(item.cartItemId)}>X</button>
//                   </td>

//                 </tr>     
//               ))}
//             </tbody>
            
//           </table>
          
//           <div className="cart-total">
//             <p><strong>Total Items:</strong> {cartItems.reduce((total, item) => total + item.quantity, 0)}</p>
//             <p><strong>Total Amount:</strong> ₹{cartItems.reduce((total, item) => total + item.quantity * item.after_discount, 0)}</p>
//           </div>

//           <div className="cart-buttons">
//             <button className="button-continue-shopping" onClick={handleContinueShopping}>Shop More</button>
//             <button className="button-checkout" onClick={handleCheckout}>Confirm Order</button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Cart;



























// import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { increaseQuantity, decreaseQuantity, deleteItem } from '../../store/actions/cartActions/cartActions';
// import { useNavigate } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTrash } from '@fortawesome/free-solid-svg-icons'; // Import the specific icon

// import './Cart.css';

// const Cart = () => {
//   const dispatch = useDispatch();
//   const cartItems = useSelector((state) => state.cart.cartItems);
//   const navigate = useNavigate();

//   const handleIncreaseQuantity = (cartItemId) => {
//     dispatch(increaseQuantity(cartItemId));
//   };

//   const handleDecreaseQuantity = (cartItemId) => {
//     dispatch(decreaseQuantity(cartItemId));
//   };

//   const handleDeleteItem = (cartItemId) => {
//     dispatch(deleteItem(cartItemId));
//   };

//   const handleContinueShopping = () => {
//     navigate('/');
//   };

//   const handleCheckout = () => {
//     navigate('/login');
//   };

//   return (
//     <div className="cart-container">
//       <h3>Shopping Cart</h3>
//       {cartItems.length === 0 ? (
//         <p>Your cart is empty</p>
//       ) : (
//         <div className="cart-table-container">
//           <table className="cart-table">
//             <thead>
//               <tr>
//                 <th>Items</th>
//                 {/* <th>Actual Price</th> */}
//                 <th>Price</th>
//                 <th>Qty</th>
//                 <th>Price</th>
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {cartItems.map((item) => (
//                 <tr key={item.cartItemId}>
//                   <td>{item.item_name}</td>
//                   {/* <td>₹{item.actual_rate}</td> */}
//                   <td>₹{item.after_discount}</td>
//                   <td>
//                     <button className="quantity-btn" onClick={() => handleDecreaseQuantity(item.cartItemId)}>-</button>
//                     <span>{item.quantity}</span>
//                     <button className="quantity-btn" onClick={() => handleIncreaseQuantity(item.cartItemId)}>+</button>
//                   </td>
//                   <td>₹{item.quantity * item.after_discount}</td>
//                   <td>
//                     <button className="delete-btn" onClick={() => handleDeleteItem(item.cartItemId)}>
//                       <FontAwesomeIcon icon={faTrash} />
//                     </button>
//                   </td>
//                 </tr>     
//               ))}
//             </tbody>
//           </table>
//           <div className="cart-total">
//             <p><strong>Total Items:</strong> {cartItems.reduce((total, item) => total + item.quantity, 0)}</p>
//             <p><strong>Total Amount:</strong> ₹{cartItems.reduce((total, item) => total + item.quantity * item.after_discount, 0)}</p>
//           </div>
//           <div className="cart-buttons">
//             <button className="button-continue-shopping" onClick={handleContinueShopping}>Shop More</button>
//             <button className="button-checkout" onClick={handleCheckout}>Checkout</button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Cart;






























































// import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { increaseQuantity, decreaseQuantity, deleteItem } from '../../store/actions/cartActions/cartActions';
// import { useNavigate } from 'react-router-dom';


// import './Cart.css';

// const Cart = () => {
//   const dispatch = useDispatch();
//   const cartItems = useSelector((state) => state.cart.cartItems);

//   const navigate = useNavigate();


  
//   const handleIncreaseQuantity = (cartItemId) => {
//     dispatch(increaseQuantity(cartItemId));
//   };

//   const handleDecreaseQuantity = (cartItemId) => {
//     dispatch(decreaseQuantity(cartItemId));
//   };

//   const handleDeleteItem = (cartItemId) => {
//     dispatch(deleteItem(cartItemId));
//   };



//   const handleContinueShopping = () => {
//     navigate('/');
//   };

//   const handleCheckout = () => {
//     navigate('/login');
//   };








//   return (
//     <div className="cart-container">
//       <h3>Shopping Cart</h3>
//       {cartItems.length === 0 ? (
//         <p>Your cart is empty</p>
//       ) : (
//         <div className="cart-table-container">
//           <table className="cart-table">
//             <thead>
//               <tr>
//                 <th>Item Name</th>
//                 <th>Original Price</th>
//                 <th>Discounted Price</th>
//                 <th>Quantity</th>
//                 <th>Total Price</th>
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {cartItems.map((item) => (
//                 <tr key={item.cartItemId}>
//                   <td>{item.item_name}</td>
//                   <td>₹{item.actual_rate}</td>
//                   <td>₹{item.after_discount}</td>
//                   <td>
//                     <button className="quantity-btn" onClick={() => handleDecreaseQuantity(item.cartItemId)}>-</button>
//                     <span>{item.quantity}</span>
//                     <button className="quantity-btn" onClick={() => handleIncreaseQuantity(item.cartItemId)}>+</button>
//                   </td>
//                   <td>₹{item.quantity * item.after_discount}</td>
//                   <td>
//                     <button className="delete-btn" onClick={() => handleDeleteItem(item.cartItemId)}>Remove</button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//           <div className="cart-total">
//             <p><strong>Total Items:</strong> {cartItems.reduce((total, item) => total + item.quantity, 0)}</p>
//             <p><strong>Total Amount:</strong> ₹{cartItems.reduce((total, item) => total + item.quantity * item.after_discount, 0)}</p>
//           </div>

//           <div>
//             <button onClick={handleContinueShopping}>Continue Shopping</button>
//             <button onClick={handleCheckout}>Checkout</button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Cart;





// // components/Cart/Cart.js
// import React from 'react';
// import { useNavigate } from 'react-router-dom';

// import { useSelector, useDispatch } from 'react-redux';
// import { decreaseQuantity, deleteItem, increaseQuantity } from '../../store/actions/cartActions';
// import Header from '../../components/Layouts/Header';
// import Sidebar from '../../components/Layouts/Sidebar';
// import './Cart.css';


// const Cart = () => {
//   const cartItems = useSelector(state => state.cart.cartItems);
//   const dispatch = useDispatch();

//   const navigate = useNavigate();


//   const getTotalAmountPerItem = (item) => {
//     return (item.price * item.quantity).toFixed(2);
//   };

//   const handleDecreaseQuantity = (cartItemId) => {
//     dispatch(decreaseQuantity(cartItemId));
//   };

//   const handleIncreaseQuantity = (cartItemId) => {
//     dispatch(increaseQuantity(cartItemId));
//   };

//   const handleDeleteItem = (cartItemId) => {
//     dispatch(deleteItem(cartItemId));
//   };

//   const getGrossTotalPrice = () => {
//     return cartItems.reduce((total, item) => total + (item.price * item.quantity), 0).toFixed(2);
//   };

//   const handleAmountToPay = () => {
//     const total = cartItems.reduce((total, item) => total + (item.price * item.quantity), 0);
//     let discount = 0;
  
//     if (total >= 3001 && total <= 5000) {
//       discount = 0.10;
//     } else if (total >= 5001 && total <= 10000) {
//       discount = 0.20;
//     } else if (total >= 10001 && total <= 35000) {
//       discount = 0.25;
//     } else if (total > 35000) {
//       discount = 0.30;
//     }
  
//     const discountedTotal = total - (total * discount);
//     return discountedTotal.toFixed(2);
//   };
  

//   const getTotalWeight = () => {
//     return cartItems.reduce((total, item) => total + (item.weight * item.quantity), 0).toFixed(2) / 1000;
//   };

//   const handleContinueShopping = () => {
//     navigate('/');

//   }

//   return (
//     <>
//       {/* <Header />
//       <Sidebar /> */}
//       <div className="cart-container">
//         <h2>Cart Items</h2>
//         <table className="cart-table">
//           <thead>
//             <tr>
//               <th>Description</th>
//               <th>Price per item</th>
//               <th>Weight</th>
//               <th>Quantity</th>
//               <th>Total amount</th>
//               <th>Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {cartItems.map(item => (
//               <tr key={item.cartItemId}>
//                 <td>{item.description}</td>
//                 <td>₹{item.price}</td>
//                 <td>{item.weight} grm</td>
//                 <td>
//                   <button className="quantity-btn" onClick={() => handleDecreaseQuantity(item.cartItemId)}>-</button>
//                   {item.quantity}
//                   <button className="quantity-btn" onClick={() => handleIncreaseQuantity(item.cartItemId)}>+</button>
//                 </td>
//                 <td>₹{getTotalAmountPerItem(item)}</td>
//                 <td>
//                   <button className="delete-btn" onClick={() => handleDeleteItem(item.cartItemId)}>Delete</button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table><br/><br/><br/>
//         <h6>Total  : ₹{getGrossTotalPrice()} </h6>
//         <h6>No Discount(If Purchased upto ₹ 3000)</h6>
//         <h6>Total Weight : {getTotalWeight()} kg</h6>
//         <h6 style={{color:'red'}}>Amount to pay : ₹ {handleAmountToPay()}</h6>
//         <button className = 'button' onClick={handleContinueShopping}>Continue Shopping</button><br/>
//         {/* <button>Checkout</button> */}
//         <div>----------------------------------------------------------------------------------------------------------------------------------------------</div>
//         <br/><br/><br/><br/><br/>
//         <h4>Discount for bulk purchase on printed Books.</h4>
//         <p>₹ 3001 to ₹ 5000 --- 10%</p>
//         <p>₹ 5001 to ₹ 10000 --- 20%</p>
//         <p>₹ 10001 to ₹ 35000 --- 25%</p>
//         <p>₹ 35001 and above --- 30%</p>
//         <p>5% Cash Back offer ! for Purchase worth ₹ 35001 to ₹ 1 Lakh (Special Slab by the Govt. and Pvt. ITI against bulk purchase).
//         <br/>Terms and Conditions Apply*</p>
//         <p style={{color:'red'}}>Note : No Discount for Transparencies and wallcharts</p>

//       </div>
//     </>
//   );
// };

// export default Cart;



































// // components/Cart/Cart.js
// import React from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { decreaseQuantity, deleteItem, increaseQuantity } from '../../store/actions/cartActions';
// import Header from '../../components/Layouts/Header';
// import Sidebar from '../../components/Layouts/Sidebar';
// import './Cart.css';

// const Cart = () => {
//   const cartItems = useSelector(state => state.cart.cartItems);
//   const dispatch = useDispatch();

//   const getTotalAmountPerItem = (item) => {
//     return (item.price * item.quantity).toFixed(2);
//   };

//   const handleDecreaseQuantity = (cartItemId) => {
//     dispatch(decreaseQuantity(cartItemId));
//   };

//   const handleIncreaseQuantity = (cartItemId) => {
//     dispatch(increaseQuantity(cartItemId));
//   };

//   const handleDeleteItem = (cartItemId) => {
//     dispatch(deleteItem(cartItemId));
//   };

//   const getTotalPrice = () => {
//     return cartItems.reduce((total, item) => total + (item.price * item.quantity), 0).toFixed(2);
//   };

//   return (
//     <>
//       <Header />
//       <Sidebar />
//       <div className="cart-container">
//         <h2>Cart</h2>
//         <table className="cart-table">
//           <thead>
//             <tr>
//               <th>Description</th>
//               <th>Price per item</th>
//               <th>Quantity</th>
//               <th>Total amount</th>
//               <th>Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {cartItems.map(item => (
//               <tr key={item.cartItemId}>
//                 <td>{item.description}</td>
//                 <td>₹{item.price}</td>
//                 <td>
//                   <button className="quantity-btn" onClick={() => handleDecreaseQuantity(item.cartItemId)}>-</button>
//                   {item.quantity}
//                   <button className="quantity-btn" onClick={() => handleIncreaseQuantity(item.cartItemId)}>+</button>
//                 </td>
//                 <td>₹{getTotalAmountPerItem(item)}</td>
//                 <td>
//                   <button className="delete-btn" onClick={() => handleDeleteItem(item.cartItemId)}>Delete</button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//         <h3>Total amount: ₹{getTotalPrice()}</h3>
//       </div>
//     </>
//   );
// };

// export default Cart;









// // components/Cart/Cart.js
// import React from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { decreaseQuantity, deleteItem, increaseQuantity } from '../../store/actions/cartActions';
// import Header from '../../components/Layouts/Header';
// import Sidebar from '../../components/Layouts/Sidebar';
// import './Cart.css';

// const Cart = () => {
//   const cartItems = useSelector(state => state.cart.cartItems);
//   const dispatch = useDispatch();

//   const getTotalAmountPerItem = (item) => {
//     return (item.price * item.quantity).toFixed(2);
//   };

//   const handleDecreaseQuantity = (cartItemId) => {
//     dispatch(decreaseQuantity(cartItemId));
//   };

//   const handleIncreaseQuantity = (cartItemId) => {
//     dispatch(increaseQuantity(cartItemId));
//   };

//   const handleDeleteItem = (cartItemId) => {
//     dispatch(deleteItem(cartItemId));
//   };

//   const getTotalPrice = () => {
//     return cartItems.reduce((total, item) => total + (item.price * item.quantity), 0).toFixed(2);
//   };

//   return (
//     <>
//       <Header />
//       <Sidebar />
//       <div className="cart-container">
//         <h2>Cart</h2>
//         <table className="cart-table">
//           <thead>
//             <tr>
//               <th>Description</th>
//               <th>Price per item</th>
//               <th>Quantity</th>
//               <th>Total amount</th>
//               <th>Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {cartItems.map(item => (
//               <tr key={item.cartItemId}>
//                 <td>{item.description}</td>
//                 <td>₹{item.price}</td>
//                 <td>
//                   <button className="quantity-btn" onClick={() => handleDecreaseQuantity(item.cartItemId)}>-</button>
//                   {item.quantity}
//                   <button className="quantity-btn" onClick={() => handleIncreaseQuantity(item.cartItemId)}>+</button>
//                 </td>
//                 <td>₹{getTotalAmountPerItem(item)}</td>
//                 <td>
//                   <button className="delete-btn" onClick={() => handleDeleteItem(item.cartItemId)}>Delete</button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//         <h3>Total amount: ₹ {getTotalPrice()}</h3>
//       </div>
//     </>
//   );
// };

// export default Cart;








