import React, { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import "./Navbar.css";
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 4,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 3px',
  },
}));

const useTotalCartItems = () => {
  const cartItems = useSelector(state => state.cart.cartItems || []);
  
  if (!Array.isArray(cartItems)) {
    return 0;
  }

  return cartItems.reduce((total, item) => {
    console.log('item:', item); // Check structure
    return total + (item.quantity || 0); // Handle missing quantity
  }, 0);
};


function Navbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navRef = useRef();
  const totalItems = useTotalCartItems();

  console.log("total items", totalItems); // Log total items

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024 && isNavOpen) {
        setIsNavOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isNavOpen]);

  return (
    <header>
      <Link to="/" onClick={closeNav}><h2 style={{color:'white', textDecoration:'none'}}>Aurora</h2></Link>
      <nav ref={navRef} className={isNavOpen ? "responsive_nav" : ""}>
        <Link to="/fancy" onClick={closeNav}>Aerial Fancy</Link>
        <Link to="/chakras" onClick={closeNav}>Chakkras</Link>
        <Link to="/crackers" onClick={closeNav}>Crackers / Bombs / Bijili</Link>
        <Link to="/flowerpots" onClick={closeNav}>Flower Pots</Link>
        <Link to="/garland" onClick={closeNav}>Garland Wala</Link>
        <Link to="/fountains" onClick={closeNav}>Fountain</Link>
        <Link to="/sparklers" onClick={closeNav}>Twinklers / Sparklers</Link>
        <Link to="/rocket" onClick={closeNav}>Rockets</Link>
        <Link to="/giftbox" onClick={closeNav}>Gift Boxes</Link>
        <Link to="/payment" onClick={closeNav}>Payment</Link>
        <button className="nav-btn nav-close-btn" onClick={toggleNav}>
          <FaTimes />
        </button>
      </nav>
      <div className="header-buttons">
        <Link to="/cart" className="shopping-cart" onClick={closeNav}>
          <StyledBadge badgeContent={totalItems} color="secondary">
            <ShoppingCartIcon />
          </StyledBadge>
        </Link>
        <button className="nav-btn" onClick={toggleNav}>
          <FaBars />
        </button>
      </div>
    </header>
  );
}

export default Navbar;









































// import React, { useState, useEffect, useRef } from "react";
// import { useSelector } from 'react-redux';
// import { Link } from "react-router-dom";
// import { FaBars, FaTimes } from "react-icons/fa";
// import "./Navbar.css";
// import Badge from '@mui/material/Badge';
// import { styled } from '@mui/material/styles';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

// const StyledBadge = styled(Badge)(({ theme }) => ({
//   '& .MuiBadge-badge': {
//     right: -3,
//     top: 4,
//     border: `2px solid ${theme.palette.background.paper}`,
//     padding: '0 3px',
//   },
// }));

// const useTotalCartItems = () => {
//   const cartItems = useSelector(state => state.cart.cartItems);
//   return cartItems.reduce((total, item) => total + item.quantity, 0);
// };

// function Navbar() {
//   const [isNavOpen, setIsNavOpen] = useState(false);
//   const navRef = useRef();
//   const totalItems = useTotalCartItems();

//   const toggleNav = () => {
//     setIsNavOpen(!isNavOpen);
//   };

//   const closeNav = () => {
//     setIsNavOpen(false);
//   };

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth > 1024 && isNavOpen) {
//         setIsNavOpen(false);
//       }
//     };

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, [isNavOpen]);

//   return (
//     <header>
//       <Link to="/" onClick={closeNav}><h2 style={{color:'white', style:'none'}}>Aurora</h2></Link>
//       <nav ref={navRef} className={isNavOpen ? "responsive_nav" : ""}>
//         <Link to="/fancy" onClick={closeNav}>Aerial Fancy</Link>
//         <Link to="/chakras" onClick={closeNav}>Chakkras</Link>
//         <Link to="/crackers" onClick={closeNav}>Crackers / Bombs / Bijili</Link>
//         <Link to="/flowerpots" onClick={closeNav}>Flower Pots</Link>
//         <Link to="/garland" onClick={closeNav}>Garland Wala</Link>
//         <Link to="/fountains" onClick={closeNav}>Fountain</Link>
//         <Link to="/sparklers" onClick={closeNav}>Twinklers / Sparklers</Link>
//         <Link to="/rocket" onClick={closeNav}>Rockets</Link>
//         <Link to="/giftbox" onClick={closeNav}>Gift Boxes</Link>
//         {/* <Link to="/payment" onClick={closeNav}>Payment</Link> */}
//         <button className="nav-btn nav-close-btn" onClick={toggleNav}>
//           <FaTimes />
//         </button>
//       </nav>
//       <div className="header-buttons">
//         <Link to="/cart" className="shopping-cart" onClick={closeNav}>
//           <StyledBadge badgeContent={totalItems} color="secondary">
//           {/* <StyledBadge color="secondary"> */}

//             <ShoppingCartIcon />
//           </StyledBadge>
//         </Link>
//         <button className="nav-btn" onClick={toggleNav}>
//           <FaBars />
//         </button>
//       </div>
//     </header>
//   );
// }

// export default Navbar;































// import React, { useRef } from "react";
// import { useSelector } from 'react-redux';
// import { Link } from "react-router-dom";
// import { FaBars, FaTimes, FaShoppingCart } from "react-icons/fa";

// import "./Navbar.css";

// import Badge from '@mui/material/Badge';
// import { styled } from '@mui/material/styles';
// import IconButton from '@mui/material/IconButton';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

// const StyledBadge = styled(Badge)(({ theme }) => ({
//   '& .MuiBadge-badge': {
//     right: -3,
//     top: 4,
//     border: `2px solid ${theme.palette.background.paper}`,
//     padding: '0 3px',
//   },
// }));

// // Function to calculate total items in the cart
// const useTotalCartItems = () => {
//   const cartItems = useSelector(state => state.cart.cartItems);

//   const getTotalItems = (items = []) => {
//     if (!Array.isArray(items)) {
//       throw new TypeError("Expected an array");
//     }
//     return items.reduce((total, item) => total + item.quantity, 0);
//   };

//   return getTotalItems(cartItems);
// };

// function Navbar() {
//   const navRef = useRef();
//   const totalItems = useTotalCartItems();

//   const showNavbar = () => {
//     navRef.current.classList.toggle("responsive_nav");
//   };

//   const hideNavbar = () => {
//     if (navRef.current.classList.contains("responsive_nav")) {
//       navRef.current.classList.remove("responsive_nav");
//     }
//   };

//   return (
//     <header>
//       <Link to="/"><h1>Go Green</h1></Link>
//       <nav ref={navRef}>
//         <Link to="/fancy" onClick={hideNavbar}>Aerial Fancy</Link>
//         <Link to="/chakras" onClick={hideNavbar}>Chakkras</Link>
//         <Link to="/crackers" onClick={hideNavbar}>Crackers / Bombs / Bijili</Link>
//         <Link to="/flowerpots" onClick={hideNavbar}>Flower Pots</Link>
//         <Link to="/garland" onClick={hideNavbar}>Garland Wala</Link>
//         <Link to="/fountains" onClick={hideNavbar}>Fountain</Link>
//         <Link to="/sparklers" onClick={hideNavbar}>Twinklers / Sparklers</Link>
//         <Link to="/rocket" onClick={hideNavbar}>Rockets</Link>
//         <Link to="/giftbox" onClick={hideNavbar}>Gift Boxes</Link>
//         <button className="nav-btn nav-close-btn" onClick={showNavbar}>
// 		<FaTimes />
//         </button>
//         {/* <Link to="/cart" className="shopping-cart" onClick={hideNavbar}>
//           <StyledBadge badgeContent={totalItems} color="secondary">
//             <ShoppingCartIcon />
//           </StyledBadge>
//         </Link> */}
//       </nav>
//       <div className="header-buttons">
//         <Link to="/cart" className="shopping-cart">
//           <StyledBadge badgeContent={totalItems} color="secondary">
//             <ShoppingCartIcon />
//           </StyledBadge>
//         </Link>
//         <button className="nav-btn" onClick={showNavbar}>
// 		<FaBars />
//         </button>
//       </div>
//     </header>
//   );
// }

// export default Navbar;











// import React, { useRef } from "react";
// import { FaBars, FaTimes, FaShoppingCart } from "react-icons/fa";
// import { useSelector } from 'react-redux';
// import { Link } from "react-router-dom";
// import "./Navbar.css";

// // Function to calculate total items in the cart
// const useTotalCartItems = () => {
//   const cartItems = useSelector(state => state.cart.cartItems);

//   const getTotalItems = (items = []) => {
//     if (!Array.isArray(items)) {
//       throw new TypeError("Expected an array");
//     }
//     return items.reduce((total, item) => total + item.quantity, 0);
//   };

//   return getTotalItems(cartItems);
// };

// function Navbar() {
//   const navRef = useRef();
//   const totalItems = useTotalCartItems();

//   const showNavbar = () => {
//     navRef.current.classList.toggle("responsive_nav");
//   };

//   const hideNavbar = () => {
//     if (navRef.current.classList.contains("responsive_nav")) {
//       navRef.current.classList.remove("responsive_nav");
//     }
//   };

//   return (
//     <header>
//       <Link Link to="/"><h1>Go Green</h1></Link>
//       <nav ref={navRef}>
//         <Link to="/fancy" onClick={hideNavbar}>Aerial Fancy</Link>
//         <Link to="/chakras" onClick={hideNavbar}>Chakkras</Link>
//         <Link to="/crackers" onClick={hideNavbar}>Crackers / Bombs / Bijili</Link>
//         <Link to="/flowerpots" onClick={hideNavbar}>Flower Pots</Link>
//         <Link to="/garland" onClick={hideNavbar}>Garland Wala</Link>
//         <Link to="/fountains" onClick={hideNavbar}>Fountain</Link>
//         <Link to="/sparklers" onClick={hideNavbar}>Twinklers / Sparklers</Link>
//         <Link to="/rocket" onClick={hideNavbar}>Rockets</Link>
//         <Link to="/giftbox" onClick={hideNavbar}>Gift Boxes</Link>
//         <button className="nav-btn nav-close-btn" onClick={showNavbar}>
//           <FaTimes />
//         </button>
//         <Link to="/cart" className="shopping-cart" onClick={hideNavbar}>
//           {/* <FaShoppingCart /> {totalItems} */}
//         </Link>
//       </nav>
//       <div className="header-buttons">
//         <Link to="/cart" className="shopping-cart">
//           <FaShoppingCart /> {totalItems}
//         </Link>
//         <button className="nav-btn" onClick={showNavbar}>
//           <FaBars />
//         </button>
//       </div>
//     </header>
//   );
// }

// export default Navbar;




















// import { useRef } from "react";
// import { FaBars, FaTimes, FaShoppingCart } from "react-icons/fa";
// import { useSelector } from 'react-redux';
// import { Link } from "react-router-dom";

// import "./Navbar.css";



// function Navbar() {
// 	const navRef = useRef();

// 	const cartItems = useSelector(state => state.cart.cartItems); // Make sure this path matches your Redux state structure

// 	function getTotalItems(items = []) {
// 	  // Ensure that items is an array before calling reduce
// 	  if (!Array.isArray(items)) {
// 		throw new TypeError("Expected an array");
// 	  }
// 	  return items.reduce((total, item) => total + item.quantity, 0);
// 	}
  
// 	const totalItems = getTotalItems(cartItems);

// 	const showNavbar = () => {
// 		navRef.current.classList.toggle("responsive_nav");
// 	};

// 	const hideNavbar = () => {
// 		if (navRef.current.classList.contains("responsive_nav")) {
// 			navRef.current.classList.remove("responsive_nav");
// 		}
// 	};

// 	return (
// 		<header>
// 			<h2>Go Green</h2>
// 			<nav ref={navRef}>
// 				<Link to="/fancy" onClick={hideNavbar}>Aerial Fancy</Link>
// 				<Link to="/chakras" onClick={hideNavbar}>Chakkras</Link>
// 				<Link to="/crackers" onClick={hideNavbar}>Crackers / Bombs / Bijili</Link>
// 				<Link to="/flowerpots" onClick={hideNavbar}>Flower Pots</Link>
// 				<Link to="/garland" onClick={hideNavbar}>Garland Wala</Link>
// 				<Link to="/fountains" onClick={hideNavbar}>Fountain</Link>
// 				<Link to="/sparklers" onClick={hideNavbar}>Twinklers / Sparklers</Link>
// 				<Link to="/rocket" onClick={hideNavbar}>Rockets</Link>
// 				<Link to="/giftbox" onClick={hideNavbar}>Gift Boxes</Link>
// 				<button
// 					className="nav-btn nav-close-btn"
// 					onClick={showNavbar}>
// 					<FaTimes />
// 				</button>
// 				<FaShoppingCart/> {totalItems} 
// 			</nav>
// 			<button
// 				className="nav-btn"
// 				onClick={showNavbar}>
// 				<FaShoppingCart/>{totalItems}  {'         '}
// 				<FaBars />
// 			</button>
// 		</header>
// 	);
// }

// export default Navbar;
























// import { useRef } from "react";
// import { FaBars, FaTimes, FaShoppingCart } from "react-icons/fa";
// import "./Navbar.css";
// import { Link } from "react-router-dom";
// // import { Link } from "react-router-dom";
// // import Tamil from "./Tamil";

// function Navbar() {
// 	const navRef = useRef();

// 	const showNavbar = () => {
// 		navRef.current.classList.toggle(
// 			"responsive_nav"
// 		);
// 	};

// 	return (
// 		<header>
// 			<h2>Go Green</h2>
// 			<nav ref={navRef}>
// 				<Link to="/fancy">Aerial Fancy</Link>
// 				<Link to="/chakras">Chakkras</Link>
// 				<Link to="/crackers">Crackers / Bombs / Bijili</Link>
// 				<Link to="/flowerpots">Flower Pots</Link>
// 				<Link to="/garland">Garland Wala</Link>
// 				<Link to="/fountains">Fountain</Link>
// 				<Link to="/sparklers">Twinklers / Sparklers</Link>
// 				<Link to="/rocket">Rockets</Link>
// 				{/* <Link to="/#">Kids Special</Link> */}
// 				<Link to="/giftbox">Gift Boxes</Link>
// 				<span></span>

				

				

				
				
// 				<button
// 					className="nav-btn nav-close-btn"
// 					onClick={showNavbar}>
// 					<FaTimes />
// 				</button>
// 				<FaShoppingCart/>
// 			</nav>
// 			<button
// 				className="nav-btn"
// 				onClick={showNavbar}>
// 					<FaShoppingCart/>{' '}
// 					{'         '}
// 				<FaBars />
// 			</button>
// 		</header>
// 	);
// }

// export default Navbar;