import React from 'react';
import { useNavigate } from 'react-router-dom';
import Gpay from '../../assets/Gpay.jpeg';
import './Payment.css';

const Payment = () => {
  const navigate = useNavigate();

  // const handlePaidClick = () => {
  //   navigate('/');  // Navigate to the confirm order page
  // };

  return (
    <div className="payment-container">
      <div className="payment-info">
        <h1 className="payment-title">Payment Information</h1>
        
        <div className="info-section">
        <p><strong>GPay or PhonePe Number : </strong> 9597624265</p>
        <p>In Gpay and Phonepay name shall shown as Prabhakaran R S</p>
        </div>

        <div className="info-section">
          <h2>Bank Details</h2>
          <p><strong>Account Name :</strong> Prabhakaran R S </p>
          <p><strong>Account Number :</strong> 42968014727</p>
          <p><strong>Bank Name :</strong> State Bank of India</p>
          <p><strong>IFSC Code :</strong> SBIN0013835</p>
        </div>
      </div>

      <div className="qr-code-section">
        <div className="qr-code">
          <img src={Gpay} alt="GPay QR Code" className="gpay-image" />
        </div>
        {/* <button className="paid-button" onClick={handlePaidClick}>
        click after Paid
        </button> */}
      </div>
    </div>
  );
};

export default Payment;

















// import React from 'react';
// import Gpay from '../../assets/Gpay.jpeg';
// import './Payment.css';

// const Payment = () => {
//   return (
//     <div className="payment-container">
//       <div className="payment-info">
//         <h1 className="payment-title">Payment Information</h1>
        
//         <div className="info-section">
//         {/* <h2 style= {{color:'white', alignItems:'center'}}>9555264537</h2> */}
//           <p><strong>GPay or PhonePe Number : </strong> 9555264537</p>
//           <p>In Gpay and Phonepay name shall shown as RATHINAKUMAR SUNDARAJAN or RATHINAKUMAR S</p>
//         </div>

//         <div className="info-section">
//           <h2>Bank Details</h2>
//           <p><strong>Account Name :</strong> RATHINAKUMAR S</p>
//           <p><strong>Account Number :</strong> 088901000022331</p>
//           <p><strong>Bank Name :</strong> Indian Overseas Bank</p>
//           <p><strong>IFSC Code :</strong> IOBA0000889</p>
//         </div>
//       </div>

//       <div className="qr-code">
//         <img src={Gpay} alt="GPay QR Code" className="gpay-image" />
//       </div>
//     </div>
//   );
// };

// export default Payment;